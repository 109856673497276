import React, { useContext, useState } from "react"
import { MultiLangBody, MultiLangField } from "skillstrainer-resource-library"
import skillsTrainer from "../assets/image/affiliateMarketing/13.jpg"
import Layout from "../components/layout"
import { Link, navigate } from "gatsby"
import { WEBAPP_ROUTE_ACTIONS } from "../utils/request"
import RuppeeGray from "../assets/image/inr-gray.svg"
import Career from "../assets/image/career.png"
import GarimaWebsiteBanner from "../assets/image/GarimaWebsiteBanner.png"

import Ruppee from "../assets/image/inr.svg"
import { NavigationContext } from "../context/Navigation"
import howitworks from "../assets/pdfs/HowItWorks.pdf"
import whatsapp from "../assets/image/SVG/whatsapp.svg"
import email from "../assets/image/SVG/envelope.svg"
import { Modal } from "skillstrainer-resource-library"
import VisitorFormBuilder from "../formBuilder/visitor-form-builder"
import { Form } from "skillstrainer-resource-library"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
const { backendHost } = require("../../config")

const {
  webappHost,
  englishStarCourseId,
  garimaCourseId,
} = require("../../config")

function EnglishStar({ pageContext }) {
  const { setPath } = useContext(NavigationContext)
  const [isModal, setModal] = useState(false)
  const [course, setCourse] = useState()

  const handleButtonClick = value => {
    setModal(true)
    setCourse(value)
  }

  const handleCloseModal = () => {
    setModal(false)
    console.log("close it")
  }

  const handleSubmit = async register => {
    const payload = {
      data: {
        data: {
          full_name: register.full_name,
          email: register.email,
          phone_number: register.phone_number,
          course_name: course,
        },
      },
    }

    try {
      const result = await axios
        .post(`${backendHost}/visitors`, payload)
        .then(res => {
          setModal(false)
          if (res.status === 200) {
            toast.success("We will get back to you", {
              position: toast.POSITION.TOP_CENTER,
            })
          } else {
            toast.error("Something went wrong!", {
              position: toast.POSITION.TOP_CENTER,
            })
          }
        })
    } catch (err) {
      console.log("Error:", err)
      toast.error("Please check the givin details again!", {
        position: toast.POSITION.TOP_CENTER,
      })
    }
  }

  return (
    <MultiLangBody _key={pageContext.key}>
      <ToastContainer />

      <Layout title="English Star">
        <div className="content mx-auto">
          {/* new code */}
          <div className="pt-20"></div>
          <img
            className="rounded-t-xl"
            src={skillsTrainer}
            alt="English-star-logo"
            style={{ width: "50%", margin: "auto" }}
          />
          <section
            className="mx-auto stacked-linear py-12 mb-20 content"
            style={{ width: "100%" }}
          >
            <div className="flex flex-col lg:flex-row items-center lg:justify-evenly">
              <div className="mx-10 w-110">
                <div className="p-2 w-full rounded-3xl relative bg-white">
                  <img src={Career} className="rounded-lg w-full" />
                </div>
                <div className="flex flex-col sm:flex-row items-center justify-between my-4 mb-2 text-sm">
                  <div className="bg-white rounded-xl font-semibold px-3 py-3 w-full sm:mr-2">
                    <MultiLangField name={"courseNameSWAR"}>
                      Spoken English for Beginners
                    </MultiLangField>
                  </div>
                  <div className="bg-white rounded-xl px-3 py-1 sm:text-center w-full mt-4 sm:mt-0">
                    <p className="blue-dark2">
                      <MultiLangField name={"courseType"}>
                        English for Employability
                      </MultiLangField>
                    </p>
                    <p className="text">CEFR A1-A2</p>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row items-center justify-between">
                  <div className="py-3 px-2 bg-white rounded-xl flex items-center w-full sm:w-auto">
                    <p className="mx-2 text-sm">
                      <MultiLangField name={"swar_time"}>
                        3 months, 36 one Hour Live Sessions
                      </MultiLangField>
                    </p>
                  </div>
                  <div className="py-3 px-3 bg-white rounded-xl flex items-center w-full sm:w-auto sm:mt-0">
                    <p className="mx-2 text-sm">
                      <span className="red-dark">NSQF </span>{" "}
                      <span className="text">
                        <MultiLangField name={"level"}>Level</MultiLangField> 2
                      </span>
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between my-3 bg-white p-3 rounded-xl">
                  <div className="flex items-center bg-orange text-white font-semibold rounded-lg p-3">
                    <button
                      onClick={() =>
                        setPath("/course-details/" + englishStarCourseId)
                      }
                      className="text-sm md:text-md px-5 font-semibold"
                    >
                      <MultiLangField name={"getEnrolled"}>
                        Get Enrolled
                      </MultiLangField>
                    </button>
                  </div>
                  <div className="flex items-center">
                    <div className="flex items-center mr-2 relative max-w-max">
                      {/* <div className="bg-green-600 h-1 w-20 absolute top-1/2 left-2 transform -rotate-12"></div> */}
                      {/* <img src={RuppeeGray} className="h-4 w-4" /> */}
                      {/* <p className="text-sm text-gray-400 font-semibold">
                        <MultiLangField name={"swar_discount_price"}>
                          1200/month
                        </MultiLangField>
                      </p> */}
                    </div>

                    <div className="flex items-center">
                      <img src={Ruppee} className="h-6 w-6" />
                      <p className="text-lg green-dark font-semibold">
                        <MultiLangField name={"swar_actuall_price"}>
                          1200/month
                        </MultiLangField>
                      </p>
                    </div>
                  </div>
                </div>
                <button
                  className="my-1 w-full primary-button"
                  onClick={e =>
                    handleButtonClick("Spoken English for Beginners")
                  }
                >
                  I am Interested
                </button>
                {/* <button
                  className="my-1 w-full primary-button"
                  onClick={() =>
                    setPath("/course-details/" + englishStarCourseId)
                  }
                >
                  Register
                </button> */}
              </div>
              <div className="mx-10 w-110">
                <div className="p-2 w-full rounded-3xl relative bg-white">
                  <img
                    src={GarimaWebsiteBanner}
                    className="rounded-lg w-full"
                  />
                </div>
                <div className="flex flex-col sm:flex-row items-center justify-between my-4 mb-2 text-sm">
                  <div className="bg-white rounded-xl font-semibold px-3 py-3 w-full sm:mr-2">
                    <MultiLangField name={"courseNameSWAR"}>
                      Garima- My Dignity
                    </MultiLangField>
                  </div>
                  <div className="bg-white rounded-xl px-3 py-1 sm:text-center w-full mt-4 sm:mt-0">
                    <p className="blue-dark2">
                      <MultiLangField name={"courseType"}>
                        English to build self-esteem
                      </MultiLangField>
                    </p>
                    <p className="text">CEFR A2-B2</p>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row items-center justify-between">
                  <div className="py-3 px-2 bg-white rounded-xl flex items-center w-full sm:w-auto">
                    <p className="mx-2 text-sm">
                      <MultiLangField name={"swar_time"}>
                        3 months, 36 one Hour Live Sessions
                      </MultiLangField>
                    </p>
                  </div>
                  <div className="py-3 px-3 bg-white rounded-xl flex items-center w-full sm:w-auto sm:mt-0">
                    <p className="mx-2 text-sm">
                      <span className="red-dark">NSQF </span>{" "}
                      <span className="text">
                        <MultiLangField name={"level"}>Level</MultiLangField> 3
                      </span>
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between my-3 bg-white p-3 rounded-xl">
                  <div className="flex items-center bg-orange text-white font-semibold rounded-lg p-3">
                    <button
                      onClick={() =>
                        setPath("/course-details/" + garimaCourseId)
                      }
                      className="text-sm md:text-md px-5 font-semibold"
                    >
                      <MultiLangField name={"getEnrolled"}>
                        Get Enrolled
                      </MultiLangField>
                    </button>
                  </div>
                  <div className="flex items-center">
                    {/* <div className="flex items-center mr-2 relative max-w-max">
                      <div className="bg-green-600 h-1 w-11 absolute top-1/2 left-2 transform -rotate-12"></div>
                      <img src={RuppeeGray} className="h-4 w-4" />
                      <p className="text-sm text-gray-400 font-semibold">
                        <MultiLangField name={"swar_discount_price"}>
                          5000
                        </MultiLangField>
                      </p>
                    </div> */}

                    {/* <div className="flex items-center">
                      <img src={Ruppee} className="h-6 w-6" />
                      <p className="text-lg green-dark font-semibold">
                        <MultiLangField name={"swar_actuall_price"}>
                          4500/-
                        </MultiLangField>
                      </p>
                    </div> */}
                    <div className="flex items-center">
                      <img src={Ruppee} className="h-6 w-6" />
                      <p className="text-lg green-dark font-semibold">
                        <MultiLangField name={"swar_actuall_price"}>
                          5000
                        </MultiLangField>
                      </p>
                    </div>
                  </div>
                </div>
                <button
                  className="my-1 w-full primary-button"
                  onClick={e => handleButtonClick("Garima- My Dignity")}
                >
                  I am Interested
                </button>
                {/* <button
                  className="my-1 w-full primary-button"
                  onClick={() =>
                    setPath("/course-details/" + englishStarCourseId)
                  }
                >
                  Register
                </button> */}
              </div>
            </div>

            <div className="flex flex-col lg:flex-row items-center text-center lg:justify-evenly py-6 mt-16 bg-white rounded-xl mx-28">
              <div className="w-110" style={{ fontSize: "20px" }}>
                Become an
                <br />
                AFFILIATE PARTNER
              </div>
              <div className="w-110">
                Join the Network for unlimited benefits
                <div className="pt-6">
                  <Link
                    className="my-1 w-full  primary-button"
                    to={
                      webappHost +
                      "/?action=" +
                      WEBAPP_ROUTE_ACTIONS.referral_partner
                    }
                  >
                    Login / Signup
                  </Link>
                </div>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row items-center text-center lg:justify-evenly  mx-28">
              <marquee className="marquee pt-10">
                Demo class for English Star every Monday at 5pm.
              </marquee>
            </div>

            <div className="flex flex-col lg:flex-row items-center text-center lg:justify-evenly mt-10">
              <div className="mx-10 w-110">
                <div className="mb-3">Contact Us</div>
                <a
                  className="text-center flex justify-center mt-4 my-1 w-28  primary-button "
                  style={{ margin: "auto" }}
                  href="https://wa.me/message/W7YSPNTFCNUXL1"
                  target="_blank"
                >
                  <img src={whatsapp} />
                </a>
                <div className="mt-4"> +91 95995 74754</div>
              </div>
              <div className="mx-10 w-110">
                <a
                  className="my-1 w-full  primary-button"
                  href={howitworks}
                  target="_blank"
                  style={{ padding: "15px 15px 15px 15px" }}
                >
                  How To Enrol?
                </a>
              </div>
              <div className="mx-10 w-110">
                <div className="mb-3">Mail Us</div>
                <a
                  className="text-center flex justify-center my-1 w-28  primary-button "
                  href="mailto:englishstar@skillstrainer.in"
                  target="_blank"
                  style={{ margin: "auto" }}
                >
                  <img src={email} />
                </a>
                <div className="mt-4">englishstar@skillstrainer.in</div>
              </div>
            </div>
          </section>
          {/* new code */}
          {/* 
          <div className="flex flex-col bg-light justify-center lg:flex-row pt-36 pb-10 px-6 lg:px-0">
            <div className="p-5 flex flex-col">
              <a href={EnglishStarBrochure} target="_blank">
                <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                  <img src={skillsTrainer} className="rounded-lg w-full" />
                  <h1
                    class="inline-flex items-center"
                    style={{
                      color: "#1e1e2d",
                      fontWeight: "500",
                      margin: "0",
                      fontSize: "22px",
                    }}
                  >
                    English for Employability
                  </h1>
                  <h1
                    class="inline-flex items-center"
                    style={{
                      color: "#1e1e2d",
                      fontWeight: "500",
                      margin: "0",
                      fontSize: "22px",
                    }}
                  >
                    CEFR A1 - A2
                  </h1>
                </div>
              </a>

              <Link
                className="text-sm bg-red-dark text-center hover:opacity-90 px-4 py-2 text-white rounded-lg"
                to="/course-details/31"
              >
                Register
              </Link>
            </div>

            <div className="p-5 flex flex-col">
              <a href={GarimaBrochure} target="_blank">
                <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                  <img src={garima} className="rounded-lg w-full" />

                  <h1
                    class="inline-flex items-center"
                    style={{
                      color: "#1e1e2d",
                      fontWeight: "500",
                      margin: "0",
                      fontSize: "22px",
                    }}
                  >
                    English to build self - esteem
                  </h1>
                  <h1
                    class="inline-flex items-center"
                    style={{
                      color: "#1e1e2d",
                      fontWeight: "500",
                      margin: "0",
                      fontSize: "22px",
                    }}
                  >
                    CEFR A2 - B2
                  </h1>
                </div>
              </a>
              <Link
                className="text-sm bg-red-dark text-center hover:opacity-90 px-4 py-2 text-white rounded-lg"
                style={{
                  opacity: "0.3",
                }}
              >
                Coming Soon
              </Link>
            </div>
          </div>
          <div className="flex flex-col bg-light justify-center lg:flex-row pb-36 px-6  lg:px-0">
            <div className="p-5 flex flex-col h-full">
              <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <img src={skillsTrainer} className="rounded-lg w-full" />

                <h1
                  class="inline-flex items-center"
                  style={{
                    color: "#1e1e2d",
                    fontWeight: "500",
                    margin: "0",
                    fontSize: "22px",
                  }}
                >
                  Become an Affiliate partner
                </h1>

                <h1
                  class="inline-flex items-center"
                  style={{
                    color: "#1e1e2d",
                    fontWeight: "500",
                    margin: "0",
                    fontSize: "18px",
                  }}
                >
                  Join the network for Unlimited Benefits
                </h1>
              </div>
              <Link
                className="text-sm bg-red-dark text-center hover:opacity-90 px-4 py-2 text-white rounded-lg"
                to={
                  webappHost +
                  "/?action=" +
                  WEBAPP_ROUTE_ACTIONS.referral_partner
                }
                target="_blank"
              >
                Login/Signup
              </Link>
            </div>
            <div className="p-5 flex flex-col h-full">
              <a href={SamarthyaBrochure} target="_blank">
                <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                  <img src={samarthiya} className="rounded-lg w-full" />

                  <h1
                    class="inline-flex items-center"
                    style={{
                      color: "#1e1e2d",
                      fontWeight: "500",
                      margin: "0",
                      fontSize: "22px",
                    }}
                  >
                    Empower your house staff
                    <br />
                  </h1>
                  <h1
                    class="inline-flex items-center"
                    style={{
                      color: "#1e1e2d",
                      fontWeight: "500",
                      margin: "0",
                      fontSize: "18px",
                    }}
                  >
                    Train them in English, Hygiene, Etiquette, Positive Attitude
                  </h1>
                </div>
              </a>
              <Link
                className="text-sm bg-red-dark text-center hover:opacity-90 px-4 py-2 text-white rounded-lg"
                style={{
                  opacity: "0.3",
                }}
              >
                Coming Soon
              </Link>
            </div>
          </div> */}

          <Modal isOpen={isModal} onClose={handleCloseModal}>
            <div
              style={{ maxHeight: "40rem" }}
              className="flex font-poppins px-8"
            >
              <Form
                formBuilder={VisitorFormBuilder}
                className="mt-2 grid grid-cols-2 gap-x-2"
                submitButton={{
                  text: "Save",
                  className:
                    "btn flex bg-orange text-white font-semibold rounded-lg p-3",
                }}
                onSubmit={handleSubmit}
              />
            </div>
          </Modal>
        </div>
      </Layout>
    </MultiLangBody>
  )
}

export default EnglishStar
