import React, { useEffect, useState } from "react"

function VisitorFormBuilder() {
  const [schema, setSchema] = useState([])

  useEffect(() => {
    const schema = {
      full_name: {
        label: "Your Name",
        type: "text",
        required: true,
      },
      email: {
        label: "Email",
        type: "email",
        required: true,
      },
      phone_number: {
        label: "Phone Number",
        type: "text",
        required: true,
      },
    }
    setSchema(schema)
  }, [])
  return schema
}

export default VisitorFormBuilder
